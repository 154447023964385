<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <b-alert
        :show="dismissCountDown"
        variant="info"
        @dismissed="onDismissed"
        @dismiss-count-down="countDownChanged"
      >
        {{ $t("passwordRecovery.redirect") }} {{ dismissCountDown }}
      </b-alert>
      <div class="box text-center">
        <b-card class="inner p-2 p-md-5 shadow">
          <simple-loader v-if="loading" />
          <b-form v-if="!loading" @submit.stop.prevent="handleSubmit">
            <b-card-text class="text-left text-uppercase mb-3">
              <h3>{{ $t("passwordRecovery.title") }}</h3>
            </b-card-text>
            <b-form-group
              :state="state.userMail"
              :invalid-feedback="$t('passwordRecovery.invalid-email')"
            >
              <b-input-group>
                <b-input-group-prepend is-text :state="state.userMail">
                  {{ $t("passwordRecovery.field") }}
                </b-input-group-prepend>
                <b-form-input
                  id="PasswordRecoveryUser"
                  v-model="form.userMail"
                  :state="state.userMail"
                  :placeholder="$t('passwordRecovery.alias')"
                  type="email"
                />
              </b-input-group>
            </b-form-group>
            <b-row class="mb-3">
              <b-col sm="12" md="6" class="mb-2 mb-md-0">
                <b-button
                  id="PasswordRecoveryButton"
                  block
                  variant="primary"
                  @click="handleSubmit()"
                  >{{ $t("passwordRecovery.button.recover") }}</b-button
                >
              </b-col>
              <b-col sm="12" md="6">
                <b-button
                  id="PasswordRecoveryBack"
                  block
                  variant="primary"
                  @click="onLeave"
                  >{{ $t("passwordRecovery.button.back") }}</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "recovery-view",
  components: {},
  data() {
    return {
      form: {
        userMail: "",
      },
      state: {
        userMail: null,
      },
      dismissSecs: 5,
      dismissCountDown: 0,
    };
  },
  methods: {
    ...mapActions("User", ["putPasswordRecovery"]),
    checkFormValidity() {
      let valid = (this.state.userMail = true);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.form.userMail))
        this.state.userMail = valid = false;
      return valid;
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.putPasswordRecovery({
        userMail: this.form.userMail,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t("passwordRecovery.title"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$router.push("/login");
        })
        .catch((errorResponse) => {
          if (errorResponse.status == 308) {
            this.dismissCountDown = this.dismissSecs;
          }
          this.$bvToast.toast(errorResponse.data.text, {
            variant: "danger",
            title: this.$t("passwordRecovery.title"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.state.userMail = null;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    onDismissed() {
      this.dismissCountDown = 0;
      window.location.href = "https://www.clinicalsite.org/resetpw";
    },
    onLeave(evt) {
      evt.preventDefault();
      this.form.userMail = "";
      this.state.userMail = null;
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING",
    }),
  },
};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
</style>
